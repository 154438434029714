<template>
  <div>
    <div v-if="value">
      <v-text-field v-model="select" :label="value.label">
      </v-text-field>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      default: null,
    },
  },
  data: () => ({
    select: null,
    updated: false,
    res: {},
  }),
  created() {
    this.res[`${this.value.id}`] = {};
  },
  computed: {},
  components: {},
  watch: {
    select() {
      if (this.select != null) {
        this.res[`${this.value.id}`][`id`] = this.value.id;
        this.res[`${this.value.id}`][`label`] = this.value.label;
        this.res[`${this.value.id}`][`value`] = this.select;
        this.$emit("updateResult", this.res);
        // this.$emit("newValue", {label: this.value.label, value: this.select, id: this.value.id});
        this.updated = !this.updated;
      }
    },
  },
  methods: {},
};
</script>

<style>
</style>