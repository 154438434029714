<template>
  <div>
    <div v-if="fields_arr">
      <v-card
        :elevation="5"
        v-for="field in fields_arr"
        :key="field.id"
        class="my-3 mx-3"
      >
        <v-card-text>
          <!-- {{ field.myValue }} -->
          <mySelect
            v-if="field.type === 'select'"
            :value="field"
            @updateResult="updateResult"
          />
          <myTextField
            v-if="field.type === 'text'"
            :value="field"
            @updateResult="updateResult"
          >
          </myTextField>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import myTextField from "@/components/PCB/componentsCreator/myTextField";
export default {
  name: "fields",
  props: {
    fields: {
      default: null,
    },
    value: {
      default: "",
    },
  },
  data: () => ({ res: {} }),
  created() {
    this.res.fields = {};
    this.res.id = this.fields.id;
    // console.log("fields!!!!!!!!!!!!!!!!!!!", this.fields);
  },
  computed: {
    fields_arr() {
      if (this.fields) {
        let arr = null;
        arr = Object.keys(this.fields).map((key) => ({
          ...this.fields[key],
          id: key,
        }));
        return arr;
      }
      return {};
    },
  },
  components: {
    mySelect: () => import("@/components/PCB/componentsCreator/mySelect"),
    myTextField,
  },
  methods: {
    updateResult(payload) {
      let payload_new = payload;
      let key_1 = Object.keys(payload_new)[0];
      if (this.res.fields[key_1]) {
        this.res.fields[key_1] = payload_new[key_1];
      } else {
        this.res.fields = { ...this.res.fields, ...payload_new };
      }
      // console.log("new res", JSON.stringify(this.res));
      this.$emit("updateResult", this.res);
    },
    // newValue(payload) {
    //   this.$emit("newValue",  payload );
    // },
  },
};
</script>